<template>
  <Drawer
    title="筛选"
    :closable="false"
    :visible="visible"
    :after-visible-change="afterVisibleChange"
    :destroy-on-close="false"
    :size="'small'"
    @close="onClose"
    @onSubmit="onSubmin"
  >
    <k-form-build
      ref="KFB"
      :value="jsonData"
      :text-display="textDisplay"
    />
  </Drawer>
</template>

<script>
import Drawer from '@/components/CbDrawer/index'
export default {
  name: 'ScreenRoster',
  components: {
    Drawer
  },
  data() {
    return {
      visible: false,
      textDisplay: true,
      jsonData: {},
      width: null,
      dataList: {}
    }
  },
  methods: {
    show(data, width) {
      this.width = width
      this.jsonData = data
      this.visible = true
      this.$nextTick(() => {
        if (this.dataList) this.$refs.KFB.setData(this.dataList)
      })
    },
    // 确认
    async onSubmin() {
      const params = await this.$refs.KFB.getData() // 获取表单参数
      this.dataList = params
      this.$emit('screenRosterParams', params)
      this.onClose()
    },
    // 取消
    onClose() {
      this.visible = false
      this.$refs.KFB.reset()
    },
    reset() {
      this.dataList = {}
    },
    // 监听抽屉 true false
    afterVisibleChange(val) {
      if (!val) {
        this.onClose()
      }
    },
    // 判断时间戳大小
    getTimeFormat(time) {
      const times = (new Date(time).getTime()) / 1000
      return times
    }
  }
}
</script>

<style lang="less" scoped>
</style>
